import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyFormTextArea = lazy(
  () => import("./form-textarea-BBbB6aEK.js").then((module) => ({
    default: module.FormTextArea
  }))
);
const LazyTextArea = lazy(
  () => import("./text-area-u6YwD6bg.js").then((n) => n.t).then((module) => ({
    default: module.TextArea
  }))
);
function FormTextArea({
  uuid: _uuid,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyFormTextArea, { ...props });
}
const useFormTextAreaContent = () => {
  return useMemo(
    () => ({
      formTextarea: {
        component: FormTextArea
      }
    }),
    []
  );
};
export {
  LazyFormTextArea as FormTextArea,
  LazyTextArea as TextArea,
  useFormTextAreaContent
};
